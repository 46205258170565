import avatar from './image/icon_tio.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="cont">
          <img src={avatar} className="App-logo" id="box" alt="logo" />
        </div>
        <p className='App-margin'>
          Satrio Setiawan I Lintang
        </p>
        <p
          className="App-title"
        >
         Web Programmer
        </p>
      </header>
    </div>
  );
}

export default App;
